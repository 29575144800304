<template>
  <a class="white-button"
     :href="link">
    <i v-if="leftIcon"
       :class="`${leftIcon}`"
       class="mr-2"></i>
    <span v-if="text">{{text}}</span>
    <i v-if="icon"
       :class="`${icon}`"></i>
  </a>
</template>

<script>
export default {
  name: 'WhiteButton',
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    leftIcon: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: 'javascript:;',
    },
  },
}
</script>

<style lang="scss" scoped>
.white-button {
  background: #ffffff;
  width: 100%;
  padding: 8px 10px;
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #0a1f44;
  text-align: center;

  & > i {
    margin-left: 10px;
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 12px;
    color: #0a1f44;
  }

  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }
}
</style>